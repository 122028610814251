<template>
  <div>
    <h2 class="text-2xl font-bold">Disbursement</h2>

    <div class="grid grid-cols-2 mb-4">
      <div class="col-span-1">
        <tabs :tabs="tabs" v-model="tab" />
      </div>
      <div class="col-span-1">
        <div class="relative">
          <input
            type="search"
            name="search"
            class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-14 w-full"
            placeholder="Search for employee’s name, status, etc."
            v-model="searchQuery"
            ref="searchField"
          />
          <ion-icon
            name="search-outline"
            class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
            style="top: 50%; transform: translateY(-50%);"
          />
        </div>
      </div>
    </div>

    <component
      :is="tab"
      :searchQuery="searchQuery"
      :search-field="$refs.searchField"
    />
  </div>
</template>

<script>
export default {
  components: {
    accepted: () => import("./Accepted"),
    verified: () => import("./Verified"),
    confirmed: () => import("./Confirmed"),
    disbursed: () => import("./Disbursed"),
    pending: () => import("./Pending")
  },
  data() {
    return {
      searchQuery: "",
      tab: "accepted",
      tabs: [
        { name: "pending", title: "Pending" },
        { name: "accepted", title: "Accepted" },
        { name: "verified", title: "Verified" },
        { name: "confirmed", title: "Confirmed" },
        { name: "disbursed", title: "Disbursed" }
      ]
    };
  },
  watch: {
    tab(tab) {
      if (this.$route.query.tab !== tab) {
        this.$router.replace({ ...this.$route, query: { tab } });
      }
    }
  },
  mounted() {
    if (this.$route.query.tab?.match(/accepted|verified|pending|disbursed|confirmed/)) {
      this.tab = this.$route.query.tab;
    }
  }
};
</script>
